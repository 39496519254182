import apiaudio from "apiaudio";
import Crunker from "crunker";
// const apiKey = "94fb8876f3b846db86b9f0e47429bdb3"; //api.audio hasan
const apiKey = "3d9a1b61f2204d8ebc20519554f1d638"; // api.audio berkant
const JSZip = require("jszip");
var FileSaver = require("file-saver");
// import { agGridInstance, gridOptions } from "../../index.html";

const musicExamples = ["3am", "Curtaincall", "Driftingoff", "Electronic", "Emptyfaces", "Fairytale", "Fuzzboy", "Gogogone"];

// Audiospots erstellen Button

$("#createAllAudioSpots").click(async function () {
  const gridOptions = window.gridOptions;
  console.log(gridOptions.rowData);

  var header = gridOptions.columnDefs;
  var audience_params = gridOptions.rowData;

  // => parameter given => params in scripttext replacen
  const parameter = [];
  header.forEach((element) => {
    parameter.push(element.field);
  });

  apiaudio.reset();

  //Individual User Input Settings
  const voiceAuthor = document.getElementById("selectvoice").value;
  const customerid = document.getElementById("customerid").value;
  const kampagneid = document.getElementById("kampagneid").value;
  const spotduration = document.getElementById("spotduration").value;
  const spotspeed = document.getElementById("spotspeed").value;
  const customMusicFile = document.getElementById("customMusicFile");
  const uploudMultipleAudio = document.getElementById("uploudMultipleAudio");
  const backgroundMusic = document.getElementById("backgroundmusic").value; //SelectBox

  if (!customerid) return notyf.error("Kunden-ID ist nicht angebeben!");
  if (!kampagneid) return notyf.error("Kampagnen-ID ist nicht angebeben!");

  function exctractParameter(text) {
    text = text.replaceAll("[", "<");
    text = text.replaceAll("]", ">");
    text = text.replaceAll("{", "{{");
    text = text.replaceAll("}", "}}");
    return text;
  }

  //tinymce editor input
  var intro = tinymce.get("intro").getContent({ format: "text" });
  var main = tinymce.get("main").getContent({ format: "text" });
  var outro = tinymce.get("outro").getContent({ format: "text" });

  try {
    var zip = new JSZip();
    var resetButtonText = "Audiospot erstellen";
    var audioFiles = audience_params.length;
    const createdAudioFiles = [];
    var i = 0;
    var b = 0;
    var a = 1;

    $("#createAllAudioSpots").html("Audiodateien werden erstellt...");
    $("#createAllAudioSpots").prop("disabled", true);
    $("#downloadAudioFiles").unbind("click");

    apiaudio.configure({ apiKey });

    audience_params.forEach(async (audience) => {
      var scriptText = exctractParameter(`${intro} ${main} ${outro}`); //connect all sections together
      var scriptName = `${customerid}-${kampagneid}-v${b}`;
      b++;

      parameter.forEach((p) => {
        const key = audience[p];
        scriptText = scriptText.replaceAll(`{{${p}}}`, key);
      });

      console.log(scriptText); //Output Scripttext

      let script = await apiaudio.Script.create({
        scriptText: scriptText,
        scriptName: scriptName,
        projectName: "AudioPlus",
      });
      $("#createAllAudioSpots").html("Audioskript wird generiert...");

      var scriptID = script.scriptId;
      await apiaudio.Speech.create({
        scriptId: scriptID,
        voice: voiceAuthor,
        speed: spotspeed,
      });
      $("#createAllAudioSpots").html("Hintergrundmusik wird hinzugefügt...");

      var mastering = {
        scriptId: scriptID,
        endFormat: "mp3",
        soundTemplate: backgroundMusic,
      };

      // Add Custom music
      if (customMusicFile.files.length != 0 && !musicExamples.includes(backgroundMusic)) {
        var customMusic = URL.createObjectURL(customMusicFile.files[0]);
        mastering = {
          scriptId: scriptID,
          endFormat: "mp3",
        };
      }

      await apiaudio.Mastering.create(mastering);

      $("#createAllAudioSpots").html("Zip Datei wird vorbereitet...");

      let masteringResult = await apiaudio.Mastering.retrieve(scriptID);
      var audiourl = masteringResult.url;

      // add background musik & create audio files
      if (customMusic) {
        let crunker = new Crunker();
        crunker
          .fetchAudio(audiourl, customMusic)
          .then((buffers) => crunker.mergeAudio(buffers))
          .then((merged) => crunker.export(merged, "audio/mp3"))
          .then(async (output) => {
            const bloburl = output.url;
            const blob = output.blob;
            console.log(i);
            zip.file(`AudioPlus${a}.mp3`, blob);
            createdAudioFiles.push(bloburl);
            a++;

            if (createdAudioFiles.length == audioFiles) {
              console.log(createdAudioFiles);
              const audios = createdAudioFiles.length;
              notyf.success(`${audios} Audio Dateien erstellt!`);
              apiaudio.reset(); // Reset api.audio
              $("#createAllAudioSpots").html(resetButtonText);
              $("#createAllAudioSpots").prop("disabled", false);
              $("#downloadAudioFiles").prop("disabled", false);
              $("#downloadAudioFiles").addClass("text-white");

              $("#downloadAudioFiles").click(function () {
                zip.generateAsync({ type: "blob" }).then(
                  function (content) {
                    saveAs(content, "AudioPlus.zip");
                  },
                  function (err) {
                    console.log(err);
                  }
                );
              });
            }
          })
          .catch((error) => {
            throw new Error(error);
          });
      } else {
        var blob = fetch(audiourl).then((r) => r.blob());
        zip.file(`Audio${i + 1}.mp3`, blob);
        createdAudioFiles.push(audiourl);
      }

      if (createdAudioFiles.length == audioFiles) {
        const audios = createdAudioFiles.length;
        notyf.success(`${audios} Audio Dateien erstellt!`);
        // Reset
        apiaudio.reset();
        $("#createAllAudioSpots").html(resetButtonText);
        $("#createAllAudioSpots").prop("disabled", false);
        $("#downloadAudioFiles").prop("disabled", false);
        $("#downloadAudioFiles").addClass("text-white");

        $("#downloadAudioFiles").click(function () {
          zip.generateAsync({ type: "base64" }).then(function (base64) {
            location.href = "data:application/zip;base64," + base64;
            zip.remove();
            return;
          });
        });
      }
      i++;
    });

    // Handle errors
  } catch (e) {
    console.log(e);
    if (e.message) notyf.error(e.message);
    else if (e.errors) notyf.error(e.errors[0]);
    else notyf.error(e);
    apiaudio.reset();
    $("#createAllAudioSpots").html(resetButtonText);
  }
});
